import { firestoreWorkspaceCollectionRef } from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import { ReportSchema, ReportStatus, ReportWithData } from '@easy-expense/data-schema-v2';
import cuid from 'cuid';
import { User } from 'firebase/auth';

/**
 * This is a hack to get around being able to set the createBy field when updating entities
 * This will be fixed in https://linear.app/easy-expense/issue/EE-3149/extend-the-report-data-type-to-include-shared-userid
 */
export function saveReportWithNewOwner(params: {
  isNewReport: boolean;
  report: ReportWithData | null;
  clientKey: string | undefined | null;
  newStatus: ReportStatus;
  user: User | null | undefined;
  sharedWith: string;
}) {
  const { isNewReport, report, clientKey, newStatus, user, sharedWith } = params;
  const entityfields = isNewReport
    ? { ...entityFields('create', user?.uid ?? '') }
    : { ...entityFields('update', user?.uid ?? '') };

  let shared = false;
  let createdBy = report?.createdBy ?? user?.uid;

  if (sharedWith === 'everyone') {
    shared = true;
  } else {
    createdBy = sharedWith;
  }
  const key = report?.key ?? cuid();
  const parsedReport = ReportSchema.partial().parse({
    ...report,
    ...entityfields,
    createdBy,
    shared,
    client: clientKey,
    key,
    status: newStatus,
  });
  const workspaceCollectionRef = firestoreWorkspaceCollectionRef('report');
  workspaceCollectionRef.doc(key).set(parsedReport);
}
