import { Layout, Spacer } from '@easy-expense/ui-web-core';

import { WorkspaceCurrencySetting } from './WorkspaceCurrencySetting.component';
import { WorkspaceDateFormat } from './WorkspaceDateFormat.component';
import { WorkspaceDistanceUnit } from './WorkspaceDistanceUnit.component';
import { WorkspaceIcon } from './WorkspaceIcon.component';
import { WorkspaceLeave } from './WorkspaceLeave.component';
import { WorkspaceName } from './WorkspaceName.component';
import { WorkspaceSharePaymentMethod } from './WorkspaceSharePaymentMethod.component';
import { WorkspaceShowTransactionNumber } from './WorkspaceShowTransactionNumber.component';
import { WorkspaceTaxRate } from './WorkspaceTaxRateSetting.component';

export const WorkspaceSettings: React.FC = () => {
  return (
    <Layout.Column>
      <Spacer.Vertical size={24} />
      <WorkspaceIcon />

      <Spacer.Vertical size={16} />
      <WorkspaceName />

      <Spacer.Vertical size={16} />
      <WorkspaceCurrencySetting />

      <Spacer.Vertical size={16} />
      <WorkspaceDistanceUnit />

      <Spacer.Vertical size={16} />
      <WorkspaceDateFormat />

      <Spacer.Vertical size={16} />
      <WorkspaceShowTransactionNumber />

      <Spacer.Vertical size={16} />
      <WorkspaceSharePaymentMethod />

      <Spacer.Vertical size={16} />
      <WorkspaceTaxRate />

      <Spacer.Vertical size={16} />
      <WorkspaceLeave />
    </Layout.Column>
  );
};
