import { generatePublicOrgToken, sendReconciledStatements } from '@easy-expense/auth-client';
import { useWorkspaceStore, useWorkspaceKeysStore } from '@easy-expense/data-firestore-client';
import { Expense } from '@easy-expense/data-schema-v2';
import { WebEnv } from '@easy-expense/env-mobile';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import Papa from 'papaparse';
import React from 'react';
import { utils, write } from 'xlsx';

import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';
import LoadingSpinner from '../LoadingSpinner.component';
import { Modal } from '../Shared/Modal.component';

export const ReconcileFileUpload: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const [dateHeader, setDateHeader] = React.useState('');
  const [totalHeader, setTotalHeader] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [statementFile, setStatementFile] = React.useState<File | null>(null);
  const workspaceMembers = useWorkspaceStore((s) => s.workspaceMembers);
  const [token, setToken] = React.useState('');
  const { organizationID, workspaceID } = useWorkspaceKeysStore((s) => ({
    workspaceID: s.currentWorkspaceKey,
    organizationID: s.currentOrganizationKey,
  }));

  React.useEffect(() => {
    async function getToken() {
      if (organizationID && workspaceID) {
        const tokenResponse = await generatePublicOrgToken()({
          organizationID,
          workspaceID,
        });
        setToken(tokenResponse.data);
      }
    }
    if (!token) {
      getToken();
    }
  }, [organizationID, workspaceID, token]);

  type ReconciledStatementRow = {
    vendor: string;
    category: string;
    description: string;
  } & object;

  async function onUpload(files?: FileList | null) {
    setIsLoading(true);
    if (!files || files.length === 0) {
      return null;
    }

    const file = files[0];

    if (!file || (file.type !== 'text/csv' && !file.name.endsWith('.csv'))) {
      alert(getTranslation('Please upload a CSV file'));
      return;
    }

    setStatementFile(file);
    Papa.parse(file, {
      header: false, // Don't transform into objects
      // preview: 1, // Read only first row
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          alert(getTranslation('Error parsing credit card statement'));
          console.error('ERROR parsing CSV ', results.errors);
          return;
        }

        // Get headers from first row
        const csvHeaders = results.data[0] as string[];

        setHeaders(csvHeaders);
        setShowModal(true);
        setIsLoading(false);
      },
      error: (error) => {
        alert(getTranslation('Error parsing credit card statement'));
        console.error('ERROR parsing CSV error:', error);
      },
    });
  }

  /** returns true if dates are same or close enough
   * */
  function compareStatementDateWithExpenseDate(
    expense: Expense,
    statementRow: DoNotFixMeIAmAny,
  ): boolean {
    const formattedStatementDate = new Date(statementRow[dateHeader]).toLocaleDateString('en-CA');
    return expense.date === formattedStatementDate;
  }

  async function onReconcile() {
    if (!statementFile) {
      console.error('Error no statement file');
      return;
    }
    Papa.parse(statementFile as File, {
      header: true,
      skipEmptyLines: true,
      error: (error) => {
        alert(getTranslation('Error parsing credit card statement'));
        console.error('ERROR parsing CSV error:', error);
      },
      complete: async (results) => {
        setShowModal(false);
        setIsLoading(true);
        await reconciledStatement(results.data);

        setIsLoading(false);
      },
    });
  }

  function getExpenses(statementData: DoNotFixMeIAmAny[]) {
    const dates = statementData.map((s) => new Date(s[dateHeader]).toLocaleDateString('en-CA'));
    const allExpenses = Data.expenses.get();
    dates.sort();

    const minDate = dates[0] ?? '';
    const maxDate = dates[dates.length - 1] ?? '';
    if (!dates || !dates[0]) {
      return allExpenses;
    }

    return allExpenses.filter((expense) => expense.date <= maxDate && expense.date >= minDate);
  }

  async function reconciledStatement(statementData: DoNotFixMeIAmAny[]) {
    const matchedExpenseKeys: string[] = [];
    let reconciledStatements: ReconciledStatementRow[] = [];
    const unreconciledStatements: DoNotFixMeIAmAny[] = [];

    const expenses = getExpenses(statementData);
    for (const statementRow of statementData) {
      const matchedExpense = expenses.find((expense) => {
        if (
          compareStatementDateWithExpenseDate(expense, statementRow) &&
          expense.total.toString() === statementRow[totalHeader]
        ) {
          matchedExpenseKeys.push(expense.key);
          return true;
        }
      });

      if (matchedExpense) {
        const vendor = Data.vendors.getByKey(matchedExpense?.vendor)?.value.name;
        const category = Data.expenseCategories.getByKey(matchedExpense?.category)?.value.name;
        const workspaceMember = workspaceMembers[matchedExpense.createdBy];
        const createdByName = workspaceMember?.displayName ?? 'Anonymous';

        reconciledStatements.push({
          ...statementRow,
          vendor,
          category,
          description: matchedExpense?.desc,
          createdByName,
          expenseURL: `${window.location.origin}/expense/${matchedExpense?.key}`,
          receiptsURL: getReceiptsURL(matchedExpense),
        });
      } else {
        unreconciledStatements.push({
          ...statementRow,
          vendor: '',
          category: '',
          description: '',
          expenseURL: '',
          receiptsURL: '',
        });
      }
    }

    const { unmatchedExpenses, reconciledStatements: splitUnreconciledStatments } =
      await handleSplitExpenses({
        expenses,
        matchedExpenseKeys,
        unreconciledStatements,
      });

    reconciledStatements = [...reconciledStatements, ...splitUnreconciledStatments];

    const formattedUnmatchedExpenses = unmatchedExpenses.map((expense) => {
      const vendor = Data.vendors.getByKey(expense?.vendor);
      const category = Data.expenseCategories.getByKey(expense?.category)?.value.name;
      const workspaceMember = workspaceMembers[expense.createdBy];
      const createdByName = workspaceMember?.displayName ?? 'Anonymous';

      return {
        ...expense,
        createdByName,
        vendorName: vendor?.value.name,
        category,
      };
    });
    await sendStatementFileToSupport(formattedUnmatchedExpenses, 'unnmatched-expenses.xlsx');
    await sendStatementFileToSupport(reconciledStatements, 'reconciled-statement.xlsx');
  }

  async function handleSplitExpenses(params: {
    expenses: Expense[];
    matchedExpenseKeys: string[];
    unreconciledStatements: DoNotFixMeIAmAny[];
  }) {
    let unmatchedExpenses = params.expenses.filter(
      (expense) => !params.matchedExpenseKeys.includes(expense.key),
    );
    const unreconciledStatements = [...params.unreconciledStatements];
    const reconciledStatements: DoNotFixMeIAmAny[] = [];
    const unmatchedExpensesMap: Map<string, Expense[]> = new Map();

    unmatchedExpenses.forEach((expense) => {
      const dateVendor = `${expense.date}${expense.vendor}`;
      const unmatchedExpensesByDateVendor = unmatchedExpensesMap.get(dateVendor) ?? [];
      unmatchedExpensesMap.set(dateVendor, [...unmatchedExpensesByDateVendor, expense]);
    });

    unmatchedExpensesMap.forEach((unmatchedExpensesByDateVendor) => {
      if (unmatchedExpensesByDateVendor.length <= 1) {
        return;
      }
      const total = unmatchedExpensesByDateVendor.reduce((acc, exp) => ({
        ...exp,
        total: acc.total + exp.total,
      })).total;

      const unreconciledStatement = unreconciledStatements.find((unreconciledStatementRow) =>
        unmatchedExpensesByDateVendor.some((exp) => {
          if (
            compareStatementDateWithExpenseDate(exp, unreconciledStatementRow) &&
            total === Number(unreconciledStatementRow[totalHeader])
          ) {
            unmatchedExpenses = unmatchedExpenses.filter(
              (unmatchedExp) =>
                !(exp.vendor === unmatchedExp.vendor && exp.date === unmatchedExp.date),
            );
            return true;
          }
        }),
      );

      const unmatchedExpense = unmatchedExpensesByDateVendor.pop();
      const vendorKey = unmatchedExpense?.vendor;
      const categoryKey = unmatchedExpense?.category;
      const workspaceMember = workspaceMembers[unmatchedExpense?.createdBy ?? ''];
      const createdByName = workspaceMember?.displayName ?? 'Anonymous';

      reconciledStatements.push({
        ...unreconciledStatement,
        vendor: Data.vendors.getByKey(vendorKey)?.value.name ?? '',
        category: Data.expenseCategories.getByKey(categoryKey)?.value.name ?? '',
        createdByName,
        expenseURL: `${window.location.origin}/expense/${unmatchedExpense?.key}`,
        receiptsURL: getReceiptsURL(unmatchedExpense),
      });
    });

    return { unmatchedExpenses, reconciledStatements };
  }

  async function sendStatementFileToSupport(jsonData: any[], fileName: string) {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(jsonData);

    utils.book_append_sheet(wb, ws, 'Expenses');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const base64String = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          // Remove the data URL prefix (e.g., "data:application/vnd.ms-excel;base64,")
          const base64 = reader.result.split(',')[1] ?? '';
          resolve(base64);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob);
    });

    await sendReconciledStatements()({
      fileName,
      fileBuffer: base64String,
      organizationID,
      workspaceID,
    });

    if (
      window.location.host === 'localhost:19006' ||
      window.location.host === 'https://beta-staging.easy-expense.com/'
    ) {
      // download files when testing but real users should get the reconciled statement from Hung
      const link = document.createElement('a');

      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    }
  }

  function getReceiptsURL(expense: Expense | undefined) {
    if (!expense || !expense?.key || !expense?.receipts.length) {
      return '';
    }
    return `${WebEnv.firebase.cloudPrefix}/reconciliation-expenseReceipts/${expense.key}?token=${token}`;
  }

  if (isLoading) {
    return (
      <Layout.Column center grow>
        <Layout.Row center>
          <LoadingSpinner />
        </Layout.Row>
      </Layout.Column>
    );
  }

  return (
    <Layout.Column
      grow
      center
      onDrop={(event: DragEvent) => {
        event.preventDefault();
        onUpload(event?.dataTransfer?.files);
      }}
      onDragOver={(event: DragEvent) => event.preventDefault()}
    >
      <label
        htmlFor="file"
        style={{
          borderStyle: 'dashed',
          borderWidth: 1,
          borderRadius: 6,
          borderColor: theme.colors.brandPrimary,
          backgroundColor: theme.colors.brandPrimaryXLight,
          margin: 32,
          padding: 32,
          display: 'flex',
        }}
      >
        <Layout.Column center style={{ justifyContent: 'space-between' }}>
          <Icon name="receipt-outline" size={23} color={theme.colors.brandPrimary} />
          <Spacer.Vertical size={12} />
          <OpenSans.Pressable weight="bold-700">
            {getTranslation('Drag & drop credit card statement')}
          </OpenSans.Pressable>
          <Spacer.Vertical size={4} />
          <OpenSans.Pressable size="xs-12">
            {getTranslation('We will reconcile automatically')}
          </OpenSans.Pressable>
          <input
            type="file"
            id="file"
            accept=".csv"
            onChange={(event) => {
              onUpload(event.target.files);
              event.target.value = ''; // so that you can re-upload the same file
            }}
            style={{ display: 'none' }}
          />
        </Layout.Column>
      </label>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        footer={
          <Layout.Row
            style={{
              borderTop: `2px solid ${theme.colors.grayXLight}`,
            }}
            px={32}
            py={24}
            justify="flex-end"
          >
            <Button.Primary onClick={onReconcile} content={getTranslation('Reconcile')} />
          </Layout.Row>
        }
      >
        <Layout.Column style={{ maxWidth: 700 }}>
          <OpenSans.Primary size={36} weight="bold-700">
            {getTranslation('Reconcile Statement')}
          </OpenSans.Primary>

          <Spacer.Vertical size={24} />

          <Layout.Row
            align
            style={{
              borderTop: `1px solid ${theme.colors.inputBorder}`,
            }}
            px
            py
          >
            <Layout.Column
              style={{
                maxWidth: 350,
              }}
            >
              <Layout.Row align>
                <OpenSans.Primary size={16} weight="bold-700">
                  {getTranslation('Date')}
                </OpenSans.Primary>
              </Layout.Row>
              <Layout.Row align>
                <OpenSans.Secondary size={16}>
                  {getTranslation(
                    'Pick the column from your statement that corpresponds to the date of the receipts',
                  )}
                </OpenSans.Secondary>
              </Layout.Row>
            </Layout.Column>
            <Spacer.Horizontal size={24} />
            <Layout.Column>
              <LabelTextField label="" active={false}>
                <Layout.Row
                  style={{
                    width: '100%',
                    fontFamily: 'Poppins_Light',
                    position: 'relative',
                  }}
                >
                  <Icon name="calendar-outline" size={20} style={{ paddingRight: 10 }} />
                  <select
                    value={dateHeader}
                    onChange={(e) => setDateHeader(e.target.value)}
                    style={{
                      border: 'none',
                      width: '100%',
                      background: 'transparent',
                      zIndex: 1,
                    }}
                  >
                    <option value="">Select date column</option>
                    {headers.map((header) => (
                      <option key={header} value={header}>
                        {header}
                      </option>
                    ))}
                  </select>

                  <Icon name="chevron-down" size={20} style={{ paddingLeft: 10 }} />
                </Layout.Row>
              </LabelTextField>
            </Layout.Column>
          </Layout.Row>

          <Layout.Row
            align
            style={{
              borderTop: `1px solid ${theme.colors.inputBorder}`,
            }}
            px
            py
          >
            <Layout.Column
              style={{
                maxWidth: 350,
              }}
            >
              <Layout.Row align>
                <OpenSans.Primary size={16} weight="bold-700">
                  {getTranslation('Total')}
                </OpenSans.Primary>
              </Layout.Row>
              <Layout.Row align>
                <OpenSans.Secondary size={16}>
                  {getTranslation(
                    'Pick the column from your statement that corpresponds to the expense total of the receipts',
                  )}
                </OpenSans.Secondary>
              </Layout.Row>
            </Layout.Column>
            <Spacer.Horizontal size={24} />
            <Layout.Column>
              <LabelTextField label="" active={false}>
                <Layout.Row
                  style={{
                    width: '100%',
                    fontFamily: 'Poppins_Light',
                    position: 'relative',
                  }}
                >
                  <Icon name="calculator-outline" size={20} style={{ paddingRight: 10 }} />
                  <select
                    value={totalHeader}
                    onChange={(e) => setTotalHeader(e.target.value)}
                    style={{
                      border: 'none',
                      width: '100%',
                      background: 'transparent',
                      zIndex: 1,
                    }}
                  >
                    <option value="">Select total column</option>
                    {headers.map((header) => (
                      <option key={header} value={header}>
                        {header}
                      </option>
                    ))}
                  </select>

                  <Icon name="chevron-down" size={20} style={{ paddingLeft: 10 }} />
                </Layout.Row>
              </LabelTextField>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
      </Modal>
    </Layout.Column>
  );
};
